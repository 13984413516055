.virtual-list-wrapper {
  flex: 1 1 auto;
  .vistaCard {
    margin-bottom: 2.188rem !important;
  }
  .card-row-wrapper {
    display: block;
    @include media-breakpoint-down(sm) {
      display: flex;
      height: 75em !important;
    }
  }
  .card-row-mobile-wrapper {
    // display: none;
    margin-bottom: 4em;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .virtual-list-left {
    //max-width: 280px !important;  // remove to make card layout dynamic in different resolution.
    flex: 1 !important;
  }
  .virtual-list-right {
    max-width: inherit !important;
    flex: 1 !important;
  }

  .vista-search-wrapper {
    display: flex;
    margin-bottom: 1.875rem;
    align-items: center;
    .vista-serach-input {
      margin-right: 0.625rem;
      .search-input {
        background: #fff;
        border-radius: 0.5rem;
        .icon-search {
          color: #666;
        }
      }
    }
    .vista-search-select {
      margin-right: 0.625rem;
      .search-select {
        width: 218px;
        background: #fff;
      }
      .search-select-vista-cameras {
        width: 191px;
        background: #fff;
      }
      .search-select-location {
        width: 303px;
        background: #fff;
      }
    }
  }

  .vista-search-input {
    margin-right: 0.625rem;
    .search-input {
      background: #fff;
      min-width: 230px;
      border-radius: 0.5rem;
      .icon-search {
        color: #666;
      }
    }
  }

  .vista-search-select {
    margin-right: 0.625rem;
    .search-select {
      width: 180px;
      background: #fff;
    }
  }

  .MuiOutlinedInput-input {
    padding: 15px 14px;
  }
  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 0;
  }

  .vista-search-attention .MuiFormControlLabel-root {
    margin-right: 7px;
  }
  .vista-search-current-rank .MuiFormControlLabel-root {
    margin-right: 7px;
  }
}

.vista-search-attention {
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: white;
  height: 3.3rem;
  border-radius: 0.6em;
  background-color: #fc2f3f;
  // margin-right: 0.625rem;
  @include media-breakpoint-down(sm) {
    padding: 0 6px;
    .filter-check-box {
      margin-right: 6px !important;
    }
  }
}

.vista-search-current-rank {
  padding: 26.5px 10px;
  display: flex;
  align-items: center;
  color: white;
  height: 2.81rem;
  border-radius: 0.6em;
  background-color: #4873fb;
  margin-right: 1.5rem;
  @include media-breakpoint-down(sm) {
    padding: 0 6px;
    .filter-check-box {
      margin-right: 6px !important;
    }
  }
}

.icon-size {
  width: 2em;
  height: 2em;
  text-align: center;
}

.MuiChip-root {
  background-color: #d6d6d6 !important;
}

.schedule-time-wrappr {
  .heading {
    padding: 0.6em 0em;
    font-size: 1.3rem;
    color: #3d4977;
  }

  .MuiIconButton-root {
    color: #7885b7;
  }
  label + .MuiInput-formControl {
    margin-top: 0;
  }
  .MuiInputLabel-animated,
  .MuiTouchRipple-root {
    display: none;
  }

  .MuiInput-underline:before {
    border: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0;
  }
  .MuiPaper-elevation1 {
    box-shadow: unset;
  }

  ul li {
    padding: 0.2em;
  }
}

.MuiPickersToolbar-toolbar {
  background-color: #7a7b97 !important;
}
.MuiDialogActions-root.MuiDialogActions-spacing {
  .MuiButton-textPrimary {
    color: #5f5f5f;
  }
}
.MuiPickersClock-container {
  .MuiPickersClock-pin {
    background-color: #f83245;
  }
  .MuiPickersClockPointer-pointer {
    background-color: #f83245;
  }
  .MuiPickersClockPointer-noPoint {
    background-color: #f83245;
  }

  .MuiPickersClockPointer-thumb {
    border: 14px solid #f83245;
  }
}

.ReactVirtualized__List::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.ReactVirtualized__List::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
.ReactVirtualized__List::-webkit-scrollbar-thumb {
  background-color: #838486;
}

@media (max-width: 1800px) {
}
@media (max-width: 959px) {
  .vista-container-custom {
    margin-top: 0em;
  }
}

.schedule-slot-input {
  min-width: 331px;
  min-height: 45px;
  border: 1px solid rgb(197, 197, 197);
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  position: relative;
  list-style: none;
  padding: 3px;
  margin: 0;
  @include media-breakpoint-down(xs) {
    min-width: 280px;
  }

  .MuiInputBase-input {
    display: none;
  }

  .clip-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
  }
}

.schedule-timePicker {
  border: 1px solid rgb(197, 197, 197);
  background: #ffffff;
  padding: 3px;
  margin: 0;

  .ant-picker {
    border: 0;
    width: 100%;
  }

  .icon-add {
    color: #007bff;
  }
  .icon-clear {
    color: #dc3545;
  }

  @include media-breakpoint-down(xs) {
    min-width: 280px;
  }
}

.ant-picker-dropdown {
  z-index: 2000;
}

@media (max-width: 1367px) {
  .vista-search-select {
    .search-select {
      width: 100% !important;
    }
  }
}
