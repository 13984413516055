#closeSurveyScreen button{
    background: #eb8383;
}

#closeSurveyScreen button:hover{
    background: #1976d2;
}

#closeSurveyScreen button svg use{
    fill:#fff
}