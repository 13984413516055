//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity and color as desired */
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.5s $app-sidebar-transition;
}

.app-bg {
  width: 100vw;
  height: 100%;
  background-image: url('../../../images/login-page-bg.jpg');
  background-size: cover;
  background-position: center;
}

@media (max-width:480px) {
  .app-bg {
    width: 100vw;
    height: 100vh;
    background-image: url('../../../images/loginpagebgimg.jpg');
    background-size: cover;
    background-position: center;
  }
}

.app-main {
  position: relative;
  min-height: 100vh;
  @include media-breakpoint-down(sm) {
    min-height: 75.4vh;
  }
}
.app-main-align{
  display: flex;
  align-items: center;
}

.app-content-sub-menu-toggle-width{
  width: calc(100% - ($sidebar-width-collapsed/2)) !important;
}
.app-content-sub-menu-mobile{
  width: 100% !important;
}

.d-flex-justify-sb-align-c{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-f-ebrima{
  font-family: 'Ebrima', sans-serif !important;
}

.login-back-btn {
  background-color: #172EB5 !important;
  width: 100%;
  font-size: 16px !important;
  font-weight: 600;
  box-sizing: border-box !important;
}
.login-disbaled{
  background-color: white !important;
  color: #172EB5 !important;
  outline: 2px solid #172EB5 !important;
  width: 100%;
  font-size: 16px !important;
  font-weight: 600;
  box-sizing: border-box !important;
}

.language-select-login-page{
  position: absolute;
  right: 5px;
  top: 10px;
  font-weight: 900;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

@media (max-width: 400px) {
  .login-popup-wrapper {
    width: 300px;
  }
}

.login-popup-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}

.login-popup-message {
  font-size: 14px;
  font-weight: 500;
}

.login-btn {
  background-color: #172EB5 !important;
  width: 100%;
  font-size: 16px !important;
  font-weight: 600;
  box-sizing: border-box !important;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  // transition: all 0.5s $app-sidebar-transition;
  padding-top: $header-height;
  width: 50%;
  // @include media-breakpoint-down(sm) {
  //   padding: 0px;
  // }
  &--inner {
    padding: $layout-spacer 2rem 2rem;
    display: flex;
    flex: 1;
    @include media-breakpoint-down(sm) {
      padding: 1.1rem 1.313rem 1rem 1.5rem;
    }

    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

.app-content-sidebar-fixed {
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
  }
}

.app-content-sidebar-fixed-IOS {
  min-height: 85vh;

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
  }
}

.app-main-sidebar-static {
  display: flex;
}

.top-bar-header {
  background-color: #172EB5 !important;
}

// .app-content-footer-fixed {
//   padding-bottom: $footer-height;
//   height: auto;
// }
.app-content-sidebar-collapsed {
  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width-collapsed / 2;
  }
}
