.search-background,
.filter-select {
  div {
    background-color: #fff;
  }
}

.outlet-filter {
  .search {
    width: 400px !important;
  }
}

.normal-filter {
  .search {
    width: 335px;
    margin-right: 0.857rem;
    font-weight: 500;
  }

  .search>div {
    max-height: 2.2rem;
  }

  .filter-level {
    width: 160px;
    margin-right: 0.857rem;
    font-weight: 500;
  }

  .filter-role {
    width: 266px;
    margin-right: 0.857rem;
    font-weight: 500;
  }
}

.date-filter {

  .min-date,
  .max-date {
    .months {
      width: 160px;
      margin-right: 0.857rem;
      font-weight: 500;
    }

    .days {
      width: 68px;
      margin-right: 0.857rem;
      font-weight: 500;
    }

    .years {
      width: 85px;
      font-weight: 500;
    }
  }

  .mid-to {
    font-weight: bold;
    padding: 0 1.143rem;
  }

  .go-button {
    margin-left: 1.143rem;

    a {
      padding: 0.63em 0;
      background-color: #4767e1;
    }
  }
}

.card-header-alt {
  padding: 15px 24px 15px 24px !important;

  h6 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.chart-top-button {
  background-color: #2bd153 !important;
  border-radius: 4em !important;
  padding: 5px 20px !important;
  color: #ffffff !important;

  &:hover {
    background-color: #05c132 !important;
  }
}