.custom_fc_frame {
  position: fixed !important;
  left: 187px !important;
}

.toggle-button-group {
  margin-bottom: 10px !important;
  font-style: italic !important;
  font-size: 12px !important;
  margin-top: 10px !important;
  height: 23px !important;
}

.toggle-button {
  font-style: italic !important;
  font-size: 9px !important;
  text-transform: none !important;
  cursor: pointer !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.order-analysis-container {
  display: flex !important;
  gap: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
  width: 100%;
}

.order-analysis-datablock {
  display: flex !important;
  border: 1px solid #ccc;
  padding: 8px;
  justify-content: space-between;
  border-radius: 8px;
  width: 50%;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.datablock-container {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 10px !important;
}

.datablock-title {
  margin-bottom: 20px !important;
  font-size: 21px !important;
  font-weight: bold !important;
}

.datablock-subtitle {
  margin-bottom: 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.datablock-maintitle {
  font-size: 18px !important;
}

.grid-color {
  background-color: white;
}

.swal-button-ok{
  background-color: #172EB5;
}


.swal-button--confirm{
  background-color: #172EB5;
}

.swal-button--danger {
  background-color: #e64942;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: #172EB5;
}

.swal-button--danger:not([disabled]):hover {
  background-color: #e64942;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.gallery-item {
  margin-bottom: 10px;
  text-align: center;
}

.gallery-item img {
  width: 100%;
  height: auto;
  max-width: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.swal-button--button{
  background-color: #172EB5;
}

.swal-button--button:not([disabled]):hover {
  background-color: #172EB5;
}
