.card-image-container {
  overflow: hidden;
  width: 100%;
  position: initial;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  cursor: -o-grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card-image-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.card-image-container {
  -ms-overflow-style: none;
}

.card-image-style {
  display: block;
  object-fit: contain;
  height: 100%;
  width: 100%;
  position: absolute;
}

.magnifier {
  height: 100%;
}

.floating-btn-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.icon-size {
  width: 2em;
  height: 2em;
  text-align: center;
}

.virtual-list-wrapper .MuiInput-root {
  position: relative;
  height: 6.2em;
}

.virtual-list-wrapper .MuiInput-underline:before {
  border-bottom: 0;
}

.virtual-list-wrapper .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.virtual-list-wrapper .MuiSelect-select:focus {
  background-color: none;
}

.filter-check-box {
  margin-bottom: 0 !important;
  height: 3.4em;
  padding-left: 0.3em;
  min-width: 115px;
}

.cooler-item {
  background: #ffffff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 25px 4px 5px;
  margin: 1px;
  width: 43.53px;
  height: 71.59px;
}

.cooler-item-single-data {
  background: #ffffff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  margin: 1px;
  width: 43.53px;
  height: 71.59px;
}

.cooler-item-2 {
  background: #ffffff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  //padding: 5px 0px 4px 5px;
  margin: 1px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cooler-item-2-single-data {
  background: #ffffff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.planogramProduct-item {
  // position: absolute;
  // top: -10%;
  // left: 66%;
  position: absolute;
  top: -15%;
  left: 0.7em;
}

.inStoreProduct-item {
  z-index: 2;
  position: inherit;
}

/* width */
.customScrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
.customScrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.customScrollbar::-webkit-scrollbar-thumb {
  background: #80808091;
  border-radius: 10px;
}

/* Handle on hover */
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #80808091;
  border-radius: 10px;
}

.current-rank {
  font-family: Roboto;
  font-style: normal;

  .current-rank-text {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
  }

  .current-rank-count {
    font-weight: bold;
    font-size: 44px;
    line-height: 52px;
  }

  .current-rank-count-outOf {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
}

.customized-label {
  // width: 130px;
  text-align: center;
}

.card-header-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  width:130px;
  height: 64px;
}

@media (max-width: 360px) {
  .card-header-text {
    width: 90px;
    margin-left:-12px;
  }
}

.out-of-stock-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.vista-location-product-table-data {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #333333;
}

.padding-r-2 {
  padding-right: 2em;
}

.padding-r-1 {
  padding-right: 1em;
}

.table-detail .ReactVirtualized__Table__headerColumn {
  padding-right: 1em;
}

.text-align-center {
  text-align: center;
}

.cooler-item-list {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.hr-line {
  border: 0;
  border-top: 8px solid #d3d6eb;
  border-radius: 6px;
  margin: 5px 0;
  padding: 0;
}

.vr-line {
  order: 0;
  border-top: 8px solid #d3d6eb;
  border-radius: 6px;
  margin: 5px 0;
  height: 100%;
  width: 5px;
  background: #d3d6eb;
  padding: 0;
}

.cooler_data_container {
  display: grid;
  padding: 5px;
}

.cooler-image-item {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.cooler_data_container__item {
  width: 90px; 
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cooler_data_container__item:first-of-type {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  text-align: end;
}

.cooler_data_container__item:last-of-type {
  grid-column: 1 / span 2;
  grid-row: 2 / span 2;
}

.location_header_card_1 {
  padding: 1.3rem 1.125rem 1.3rem 1.125rem;
  flex: 1;
  font-weight: 700;
}

.location_header_card_1_icon {
  cursor: pointer;
  flex: 0.09;
  padding: 1rem 1.125rem 0rem;
}

.location_body_card_1_container {
  flex-shrink: 1 !important;
  object-fit: contain !important;
  width: 100% !important;
  background-color: #000 !important;
  position: relative !important;
}

.location_body_card_1_name {
  position: absolute;
  color: #fff;
  bottom: 0;
  padding: 0rem 1rem;
  font-size: 16px;
}

.position-left {
  left: 0;
}

.font_weight_500 {
  font-weight: 500;
}

.font_weight_600 {
  font-weight: 600 !important;
}
.avgStock{
  flex-shrink: 0;
  margin-left: 10px;
}
.location_body_card_1_img {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  background-color: #000 !important;
  object-fit: contain !important;
}
#outlet-ordering-detail-left-container{
  padding: 10px !important;
}

.vista-location-label {
  font-size: 12px;
  font-weight: 600
}

.empty-pln-store-prd {
  border: 2px solid grey;
  background: white;
}

.blank-pln-store-prd {
  border: 2px solid black;
}

.customized-label {
  font-family: Roboto;
  font-style: normal;
  border-radius: 5px !important;
  padding: 10px 15px 10px 15px;
  .customized-label-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
  }
}

@media only screen and (max-width: 1500px) {
  .customized-label {
    padding: 5px 10px 5px 10px;

    .customized-label-text {
      white-space: nowrap;
    }
  }

}