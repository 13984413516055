//  Core

.app-page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $transition-base;
  position: relative;
  color: #fff;

  .cameraIconTitle {
    color: rgb(71, 113, 246);
    font-size: 1.5em;
    @include media-breakpoint-down(sm) {
      font-size: 2.5em;
    }
  }

  .pageIconTitle {
    color: rgb(71, 113, 246);
    font-size: 2.1em;
    margin-right: 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.8;
    }
  }

  @include media-breakpoint-down(md) {
    // flex-direction: column;
    padding: 2rem 1.2rem 0rem 0rem;
    border-bottom: none;
    margin: -2rem 0 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    // flex-direction: column;
    border-bottom: none;
    position: relative;
    max-width: 10rem;
    height: 5rem;
    margin: -2rem 0 -0.5rem;
  }
  &--dark {
    .MuiBreadcrumbs-li,
    .MuiBreadcrumbs-li a,
    .MuiBreadcrumbs-li p,
    .MuiBreadcrumbs-separator,
    .app-page-title--heading {
      color: rgba($white, 1);
    }
  }

  &--first {
    flex-grow: 1;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      // flex-direction: column;
      // margin-bottom: ($spacer * 1.5);
    }
  }

  &--second {
    display: flex;
    align-items: center;
  }
  &--heading {
    padding-right: ($spacer);

    @include media-breakpoint-down(md) {
      padding-right: 0;
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      width: 8rem;
    }
    h1 {
      margin: 0;
      color: #fff;
      font-size: 20px;
      padding-left: 16px;
      font-weight: 500;
      @include media-breakpoint-down(sm) {
        font-size: 1.3em;
        padding-left: 8px;
      }
    }
  }
  &--iconbox {
    background: $white;
    @include border-radius(100%);
    display: flex;
    margin: 0 ($spacer * 1.5) 0 0;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin: 0 0 ($spacer * 1.5);
    }

    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }

  &--description {
    margin: ($spacer / 2) 0 0;
    font-size: $font-size-base * 1.1;
    opacity: 0.6;
    font-weight: normal;
  }

  // Alternate style 1

  &.app-page-title-alt-1 {
    background: $white;
    padding-top: ($spacer);
    padding-bottom: ($spacer);

    .app-page-title--heading {
      display: flex;
      align-items: left;

      h1 {
        font-size: $font-size-lg;
        word-wrap: break-word;
      }
    }

    .app-page-title--description {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 0 ($spacer / 2);
      font-size: $font-size-base;
    }

    .app-page-title--iconbox {
      box-shadow: none;
      background: none;
      margin-right: ($spacer / 2);

      width: 32px !important;
      height: 32px !important;

      .d-70 {
        width: 32px !important;
        height: 32px !important;

        .display-3 {
          font-size: $font-size-lg !important;
        }
      }
    }
  }

  // Alternate style 2

  &.app-page-title-alt-2 {
    margin: (-$layout-spacer) 0 $layout-spacer;
    padding: $layout-spacer;
    @include border-bottom-radius($border-radius);
  }

  // Alternate style 3

  &.app-page-title-alt-3 {
    margin: 0 0 $layout-spacer;
    padding: ($layout-spacer / 1.5) $layout-spacer;
    @include border-radius($border-radius);

    .app-page-title--iconbox {
      @include border-radius(100%);
    }
  }
}

.speedial-wrapper {
  position: absolute;
  top: 50%;
  right: ($spacer);
  margin-top: -28px;

  .MuiSpeedDial-root {
    right: 0;
    position: absolute;
  }
}

.fontsIcon {
  margin-right: 16px !important;
  font-size: 1.5rem !important;
  width: 1em !important;
}

.BodyGrid {
  width: 100%;
  border: 1px solid #e0e0e0;
}
.nav-fa-icon {
  fill: currentColor;
  width: 1em !important;
  height: 1em !important;
  display: inline-block !important;
  font-size: 1.5rem !important;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0 !important;
  user-select: none !important;
  display: flex !important;
  align-items: center;
  margin-right: 16px !important;
}

.app-page-title--heading-media {
  width: 500px;
  padding-left: 15px;
}

@media only screen and (max-width: 1500px) {
  .app-page-title--heading-media {
    width: 400px;
  }
}

@media only screen and (max-width: 1400px) {
  .app-page-title--heading-media {
    width: 250px;
  }
}
