@import "variables";
// Core
body {
  background-color: #eeeff8;
  .app-header {
    height: $header-height;
    display: flex;
    flex-direction: row;
    box-shadow: none !important;
    // @include media-breakpoint-down(sm) {
    //   position: relative;
    // }
  }

  @media print {
    .app-header {
      display: none !important;
    }
  }

  .app-header-collapsed-sidebar {
    @include media-breakpoint-up(lg) {
      padding-left: $sidebar-width-collapsed / 8;
    }
  }

  .app-header-toolbar {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-left: ($spacer);
    padding-right: ($spacer);
    box-shadow: none !important;

    .btn-inverse:hover {
      background: rgba(255, 255, 255, 0.07);
    }
  }

  .btn-toggle-collapse {
    margin-left: 10px;
    margin-right: 10px;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .btn-toggle-collapse-closed {
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 0;
  }

  .app-logo-img {
    width: 28px;
    height: 28px;
  }

  .app-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .app-logo-btn {
    padding: $spacer / 2;
    background: rgba(255, 255, 255, 0.05);

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .app-logo-link {
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      margin-top: ($spacer * 2);
    }
    @include media-breakpoint-down(lg) {
      margin-right: $spacer;
    }
  }

  .app-logo-text {
    font-size: $font-size-base;
    font-weight: bold;
    width: auto;
    display: block;
  }

  .toggle-sidebar-btn-mobile {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
.group-name {
  font-size: 1.125rem;
}

.dropdown-menu-right {
  width: 14em;
}

#wrap {
  position: relative;
  height: 44px;

  .default-input {
    height: 44px;
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    border: none;
    outline: none;
    color: #fff;
    padding-right: 42px;
    width: 0px;
    position: absolute;
    top: 0;
    right: -8px;
    background: none;
    z-index: 3;
    transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
  }

  input[type="text"]::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
  }

  input[type="text"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  input[type="text"]::placeholder {
    color: #fff;
  }

  input[type="text"]:focus:hover {
    // border-bottom: 1px solid #bbb;
  }

  .input-onClick {
    width: 168px !important;
    background-color: #2f385d !important;
    z-index: 1 !important;
    cursor: text !important;
    border-radius: 3em;
    padding-left: 1em;
  }

  // input[type="text"]:focus, .input-width {
  //   width: 224px !important;
  //   background-color: #2f385d !important;
  //   z-index: 1 !important;
  //   cursor: text !important;
  //   border-radius: 3em;
  //   padding-left: 1em;
  // }
  .search_submit {
    background: url("../../../images/searchIcon.png") center center no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    height: 42px;
    width: 32px;
    display: inline-block;
    color: red;
    float: right;
    text-indent: -10000px;
    border: none;
    cursor: pointer;
    transition: opacity 0.4s ease;
  }

  .search_submit:hover {
    opacity: 0.8;
  }
}
