body {
  background-color: rgb(227, 245, 245);
  padding: 0px;
  margin: 0px;
}

#root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: auto;
}

.width-60 {
  width: 60% !important;
}

.MuiDataGrid-treeDataGroupingCell {
  margin-left: -10px !important
}

.MuiDataGrid-treeDataGroupingCellToggle {
  margin-right: 1px !important;
}

.bg-red {
  background-color: #991111 !important;
  color: white !important;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-max-width {
  width: fit-content !important;
}

.page-text-title {
  font-size: 1.8em !important;
  text-align: left;

  @media (max-device-width: 1024px) {
    font-size: 1.7em !important;
  }

  @media (max-device-width: 480px) {
    font-size: 1.5em !important;
  }
}

.filter-grid-container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  min-width: 1200px;
  margin-left: 13px;
}

.data-grid-replainshment {
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 20px;
}

.custom-button {
  display: flex;
  justify-content: center;
}

.custom-button button {
  border-radius: 12px;
  padding: 1px 4px;
  min-width: 80px;
  font-size: 10px;
  pointer-events: none;
  cursor: default;
}

.custom-button button:hover {
  background-color: transparent;
}

.sales-drop {
  width: "2000px";
}


.page-text-description {
  text-align: left;

  @media (max-device-width: 1024px) {
    font-size: 1em !important;
    text-align: left;
  }
}

.card {
  background-color: white;
  margin: 20px 10px;
  padding: 10px;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .cardBody {
    h2 {
      font-size: 1.5em;
      text-align: left;
      color: black;
      text-transform: capitalize;
    }

    p {
      font-size: 1.2em;
      text-align: left;
      color: black;
    }

    button {
      font-size: 1.1em;
      text-align: left;
      color: white;
      border: none;
      padding: 10px 15px;
      background-color: black;
    }
  }
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin: 0;
  padding: 15px 0;
}

.ReactVirtualized__Table__row {
  border-bottom: 0.05rem solid #e0e0e0;
  padding: 5px 0;
}

.ReactVirtualized__Table__Grid {
  overflow: none !important;
}

.ReactVirtualized__Table__headerRow {
  margin: 0;
  padding: 1.45em 0 0.97em 0;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #3e3b66;
  background-color: #f8f9ff;
}

.table-container {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.presales-column-header {
  margin: 0;
  color: #333333;
  font-size: 1.25em;
  font-weight: 400 !important;
  text-align: center;
  padding: 0.4em 0em;
  background-color: white;
}

.rank-column {
  font-size: 1.5rem;
  font-weight: 500 !important;
  color: #4068e2;
}

.presales-column {
  font-size: 0.9rem;
  font-weight: 500;
}

.virtualized-table-title {
  font-size: 1rem;
  padding: 1.813rem 0 1.313rem 1.25rem;
  font-weight: bold;
  display: inline-block;
}

.data-answer-title {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
}

.question-icon {
  color: #3d64e5;
  float: right;
  padding: 1.68em 1.68em 0 0;
}

.presales-title {
  margin: -2rem 0 3.188rem;
}

// out of stock report

.outofstock-report-board {
  margin-top: 0.68rem;
}

.scoreboard-left {
  border-radius: 18px;
  height: 8.75rem;
  border-bottom: 7px solid #2bd153;
}

.scoreboard-right {
  border-radius: 18px;
  height: 8.75rem;
  border-bottom: 7px solid #f67b28;
}

.score-num-black {
  line-height: 59px;
  padding: 0;
  margin: 0;
  font-size: 3.125rem;
  font-weight: bold;
  color: #333333;
}

.scoreboard-bottom-text {
  line-height: 1.37rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #999999;
}

.inner-scoreboard {
  padding: 1.125rem 0 1.125rem 1.4rem;
}

.score-num-red {
  line-height: 3.68rem;
  padding: 0;
  margin: 0;
  font-size: 3.125rem;
  font-weight: bold;
  color: #fc2f3f;
}

.count-description {
  font-size: 1rem;
  padding: 1.68em 1.68em 0 1.18em;
  display: inline-block;
  float: right;
}

.outofstock-column-header {
  margin: 0;
  color: #333333;
  font-size: 1.25em;
  font-weight: normal !important;
  padding: 0.4em 0em;
}

.missing-product {
  float: left !important;
  padding-left: 2.18rem;
}

.estimated-cases-lost {
  color: #fc2f3f;
}

.app-bar-select-menus .MuiOutlinedInput-input {
  padding: 5% !important;
  margin-right: 17px !important;
}

.list-record-count {
  float: right;
  padding: 1.6em 1.68em 0 0;
}

.location-column-header {
  margin: 0;
  color: #333333;
  padding: 0.4em 0em;
  background-color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.level-column-header {
  margin: 0;
  padding: 0.4em 0em;
  background-color: white;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 19px;
}

.location-table-data-numeric {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  padding: 0.4em 0em;
  background-color: white;
}

.list-header {
  padding: 1px;
  font-weight: 300;
  width: 30vw;
  height: fit-content;
}

.list-item {
  width: 30% !important;
}

.ellipse {
  width: 70% !important;
  margin: 0;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.maintenance-status-data {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  margin: 0;
  text-align: center;
  padding: 0.4em 0em;
  background-color: white;
}

.name-column-header {
  margin: 0;
  padding: 0.4em 0em;
  background-color: white;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-overflow: unset !important;
  white-space: break-spaces !important;
}

.maintenance-cards-text {
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
}

.maintenance-cards-sub-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}

.column-header-style>span {
  text-align: center;
  // padding-left: 1em !important;
  text-overflow: unset !important;
  white-space: break-spaces !important;
}

.height40 {
  height: 40vh !important;
}

.OOS-report-table-text {
  margin: 0;
  padding: 0.4em 0em;
  background-color: white;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.cursor_pointer {
  cursor: pointer;
}

.outlet-card {
  @media only screen and (max-width: 400px) {
    height: 320px;
  }
}

.subheader {
  display: flex;
  justify-content: space-between;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mark-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.location-img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.text-color-red {
  color: red !important;
}

.over-flow-auto {
  overflow: auto !important;
}

.scroll-bar::-webkit-scrollbar {
  display: none;
}

.scroll-bar {
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
}

.vista-virtual-list {
  position: "relative";
  display: "flex";
  flex-direction: "column";
  flex-shrink: 1;
}

.vista-model-bx .MuiDialog-paper {
  height: 67vh;
}

.vista-model-bx-sm .MuiDialog-paper {
  height: 35vh !important;
}

.column-text-style>span {
  text-align: right;
  text-overflow: unset !important;
  white-space: break-spaces !important;
}

.dialog-content-style .MuiDialogTitle-root {
  margin: auto;
  width: inherit;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  font-size: x-large;
}

.img-title {
  color: white;
  margin-top: 0em;
  margin-left: 1em;
  font-size: x-large;
  margin-bottom: 0.5em;
}

.time-title {
  color: white;
  margin-top: 0em;
  margin-left: 2em;
  margin-bottom: 0.5em;
}

.vista-search-current-rank input[type="checkbox"] {
  position: relative;
  width: 1em;
  height: 1em;
  color: #000000;
  border: 2px solid #fff;
  display: inline-block;
  font-size: 1.18rem;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 0px;
    left: 5px;
    width: 6px;
    height: 12px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    color: #4873fb;
    border-color: #4873fb;
    background: #fff;

    &::before {
      opacity: 1;
      border-color: #4873fb;
    }
  }
}

.chk-label {
  margin-left: 9px;
  margin-bottom: 0;
  white-space: nowrap;
}

.vista-location-chk-display {
  display: flex;
  justify-content: space-around;
}

.paddingLeft-1 {
  padding-left: 1em;
}

.margin-r-3 {
  margin-right: 1%;
}

.mr-b-1 {
  margin-bottom: 1em;
}

.linear-prg-border {
  background: #d3d3d338;
  position: relative;
  line-height: 0;
}

.linear-prg-grey {
  background: #638ec66e;
}

.linear-prg-green {
  background: #1bc943;
}

.linear-prg-yellow {
  background: #ffc926;
}

.linear-prg-orange {
  background: #f4772e;
}

.linear-prg-red {
  background: #f83245 !important;
}

.linear-prg {
  min-height: 25px !important;
  text-overflow: unset !important;
  white-space: break-spaces !important;
}

.linear-prg-label {
  color: #000;
  position: absolute;
  font-size: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  width: -webkit-fill-available;
  top: 0.8em;
}

.grid-row-color {
  background-color: #ececec !important;
}

.margin-l-3 {
  margin-left: 3%;
}

.search_product_div {
  margin-top: 0.75em;
  margin-left: 3em;
  width: 80%;
}

.excel-div {
  position: absolute;
  right: 12%;
  margin-top: 1em;
  cursor: pointer;
}

.pull-right {
  float: right !important;
  border-right: 1px solid;
  padding: 0em 1em;
}

.healthIssue-Col {
  display: flex;
  flex-direction: row;
}

.filter-container {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.filter-container>div {
  margin-top: 1em;
}

.column-header-style>span {
  text-overflow: unset !important;
  white-space: break-spaces !important;
}

.product-grid {
  max-height: 34vh;
  min-height: 34vh !important;
  margin-top: 0;
}

@media only screen and (max-width: 1400px) {
  .product-grid {
    max-height: 49vh;
    min-height: 49vh !important;
  }
}

.location-order-edit {
  color: #4873fb;
  margin-left: 1em;
  cursor: pointer;
}

.reorder-filter {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .filter-btn {
    margin-right: 0.85em;
  }

  .MuiFormControl-root {
    min-width: 8em;
  }
}

.hide-component {
  display: none !important;
}

.total-order {
  height: 6.2em;
  min-width: 8em;
  max-width: 21em;
  float: right;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px #e0e1ea, 0px 1px 1px 0px #e0e1ea, 0px 5px #5383ff,
    0px 1px 3px 0px #e0e1ea;
  padding: 1em 1em 0.75em 1.5em;

  .order-count {
    font-size: 2.5em;
    font-weight: 600;

    .na-currency {
      font-size: 0.75em;
      padding-right: 5px;
    }
  }

  .order-label {
    font-size: 0.75em;
    color: grey;
    font-weight: 300;
  }
}

.mrt-3 {
  margin-top: 3em;
}

.reorder-schedule-btn {
  margin-top: 1em !important;
  right: 3em;
}

.expand-item-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .add-item {
    margin-left: 0.75em;
    font-size: 1.5em;
    color: #c0c0c0;
    font-weight: 500;
    cursor: pointer;
  }
}

.schedule-btn {
  .filter-select div {
    background-color: #5383ff;
    color: #fff;
  }

  .MuiSelect-select.Mui-disabled {
    background-color: #e0e0e0;
    color: #b6b6b6;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline:hover {
    border-color: transparent !important;
  }

  .MuiSelect-select:focus,
  .MuiOutlinedInput-notchedOutline:hover {
    box-shadow: 0px 3px 1px -2px #e0e1ea, 0px 2px 2px 0px #e0e1ea,
      0px 1px 5px 0px #e0e1ea;
    border-radius: 5px;
  }
}

.add-prod-cont .MuiDialog-paper {
  height: 23em;
}

.add-product {
  .lablel {
    margin-top: 1em;
  }

  .MuiSelect-outlined.MuiSelect-outlined,
  .MuiOutlinedInput-input {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .product-dropdown {
    display: flex;
    flex-direction: column;
  }
}

.add-prod-cont {
  img {
    max-width: 7em;
    max-height: 10em;
  }
}

.forgot-pass {
  cursor: pointer;
  text-decoration-line: underline;
  color: #5383ff;
}

.log-screen .normal-filter .search {
  width: 100% !important;
  max-width: 302px !important;
  padding-right: 0.5em;
}

.acosta-img-cont {
  img {
    max-height: 36em;
    width: auto;
    height: 100%;
    padding: 0.5em;
  }

  p {
    margin-bottom: 0.5em;
    padding-left: 1em;
    font-weight: 500;
  }
}

.acosta-hylink {
  text-decoration: underline;
  cursor: pointer !important;
  color: #3e3e8e;
}

.white-space-pre {
  white-space: pre;
}

.display-initial {
  display: initial;
}

.white-space-initial {
  white-space: initial;
}

.replenish-link {
  cursor: pointer;
  text-decoration: underline;
  color: #4444de;
}

.replenish-table {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.showing-container {
  justify-content: flex-end;
}

.w-6-em {
  width: 6em;
}

.mob-excel-div {
  position: absolute;
  right: 21%;
}

.mt-mb {
  margin-top: -2em;
  margin-bottom: 1em;
}

.tab-export {
  display: flex;

  @media only screen and (max-width: 1223.9px) {
    display: flex;
    margin-bottom: 1em;
    margin-right: 1em;
  }
}

.kpi-table {
  .hd-title {
    font-size: 1.3em;
    font-weight: bold;
    line-height: 3em;
  }

  .MuiOutlinedInput-input {
    padding: 12px 12px !important;
  }

  .MuiFormControl-marginNormal {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0 !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px 16px 16px !important;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 1px 0;
  }
}

.dis-grid {
  display: grid;
}

.lang-util-drop-down {
  width: 90%;
  margin: auto;
}

.content-wraper {
  display: flex;
  flex-direction: column;
}

.no-gutters {
  padding: 0 !important;
}

.adjust {
  padding-bottom: 100px !important;
}

.right-filter-web .MuiDrawer-paperAnchorRight {
  width: 350px !important;
}

.right-filter-mobile .MuiDrawer-paperAnchorRight {
  width: 100% !important;
}

.filter-input .MuiOutlinedInput-input {
  padding: 12px !important;
}

.background-off-white {
  background-color: #f8f9ff !important;
}

.text-theme-blue {
  color: #252e69 !important;
}

.text-string-them {
  color: #252e69 !important;
  font-size: medium;
}

.background-theme-blue {
  color: white !important;
  background-color: #172EB5 !important;
}

.Location-Reorder-blue {
  color: white !important;
  background-color: #172EB5 !important;
}

.background-off-red {
  background-color: #e74231 !important;
  color: white !important;
}

.text-off-red {
  color: #e74231 !important;
}

.width-0 {
  min-width: 0px !important;
}

.filter-layout .filter-btn {
  width: 100%;
}

.filter-layout .filter-btn .filter-select {
  width: 100%;
}

.filter-layout .filter-btn .filter-select div {
  width: 100%;
}

.logo-img {
  width: 40%;
  height: 40%;
}

@media screen and (max-width: 480px) {
  .logo-img {
    width: 60%;
  }

  .lang-utility {
    position: relative;
    left: -2.5rem;
    top: 6rem;
    z-index: 10;
  }

  .lang-utility .lang-text {
    color: #000;
    display: block;
    width: 3rem;
  }

  .button-with-icon.remove-mobile-view {
    display: none;
  }

  .button-with-icon {
    padding: 0 !important;
    margin: 0.3rem !important;
    min-width: 0 !important;
    box-shadow: 0.05rem 0.05rem rgba(0, 0, 0, 0.144) !important;
  }

  .button-with-icon button {
    padding: 0.3rem;
  }

  .button-with-icon button svg {
    font-size: 1.5rem;
  }

  .remove-desktop-view {
    display: block;
  }

  .break-title {
    position: relative;
    left: -1.4rem;
    word-break: normal;
  }
}

.filter-layout {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

@media screen and (max-width: 600px) {
  app-content-sub-menu-toggle-width {
    width: 100% !important;
  }
}

@media screen and (min-width: 960px) {
  .remove-desktop-view {
    display: none !important;
  }
}

@media screen and (min-width: 1260px) {}

@media screen and (min-width: 1560px) {
  .move-pagination-right .css-17jjc08-MuiDataGrid-footerContainer {
    position: relative;
    left: -70px;
  }

  .move-pagination-right .css-17jjc08-MuiDataGrid-footerContainer .MuiTablePagination-selectLabel {
    position: relative;
    top: 10px;
  }

  .move-pagination-right .css-17jjc08-MuiDataGrid-footerContainer .css-levciy-MuiTablePagination-displayedRows {
    position: relative;
    top: 10px;
  }
}

.homeCardsStyle {
  width: 20%;
  padding: 0.3%;
}

/* survey answer  --start--*/

.assessment-wrapper {
  padding: 1em;
}

.layout-boxed .card {
  margin-bottom: 9px !important;
}

.assessment-wrapper .card {
  margin-bottom: 1em;
  -webkit-border-bottom-right-radius: 15px !important;
  -webkit-border-bottom-left-radius: 15px !important;
  -moz-border-radius-bottomright: 15px !important;
  -moz-border-radius-bottomleft: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  -webkit-box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
  -moz-box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
  box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
  margin-bottom: 9px !important;
  padding-top: 1em !important;
}

.assessment-wrapper #inner-area {
  height: 75vh;
  overflow-y: scroll;
  padding-right: 1em;
}

.assessment-wrapper .form-check-input {
  position: relative;
}

.assessment-wrapper form p {
  font-weight: 800;
  display: flex !important;
  line-height: 2em !important;
}

.assessment-wrapper form p div {
  margin-right: 0.5em;
}

/* width */

.assessment-wrapper #inner-area::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

/* Track */

.assessment-wrapper #inner-area::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */

.assessment-wrapper #inner-area::-webkit-scrollbar-thumb {
  background: #b7b7b7;
}

/* Handle on hover */

.assessment-wrapper .left-area {
  padding: 1em 2em;
}

.assessment-wrapper #inner-area::-webkit-scrollbar-thumb:hover {
  background: #999;
  background: rgb(240 235 248);
}

.assessment-wrapper .right-area {
  padding: 0px 4.5em;
}

.assessment-wrapper .right-area .btn-group-lg>.btn,
.assessment-wrapper .right-area .btn-lg {
  padding: 1rem 1rem;
  font-size: 1em !important;
  margin: 14px 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: rgb(103, 58, 183);
  width: 85%;
}

.add-reorder-product-image {
  width: 150px;
  height: 100px;
  background-color: #d9d9d9;
  color: #616161;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maxH10px {
  max-height: 10px !important;
}

.h50px {
  height: 50px;
}

.product-reorder-select {
  height: 50px !important;
  border-radius: 3px !important;
}

.product-popup {
  padding: .2rem 0 .5rem 0 !important;
}

.assessment-wrapper .right-area .btn-outline-secondary {
  color: #676b6f !important;
  background-color: transparent;
  background-image: none;
  border-color: #bfbfbf !important;
}

.assessment-wrapper .right-area .btn-outline-secondary:hover {
  color: #000 !important;
  background: rgb(240 235 248);
}

.assessment-wrapper .right-area .assessment-wrapper .right-area .btn.focus,
.assessment-wrapper .right-area .btn:focus {
  color: #ffffff;
}

/*********** scroller group *********/

.assessment-wrapper .scroller-group>img {
  position: absolute;
  right: 3.6em;
  width: 1em;
  cursor: pointer;
}

.assessment-wrapper .scroller-group .top-scroller {
  top: 1em;
}

.assessment-wrapper .scroller-group .bottom-scroller {
  bottom: 1em;
}

.bottom-button-group {
  padding: 20px 0px;
}

.bottom-button-group .submit-reset-btn {
  float: right;
  padding-right: 0.9em;
}

.assessment-wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b7b7b7;
}

.assessment-wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  color: #b7b7b7;
}

.assessment-wrapper :-ms-input-placeholder {
  /* IE 10+ */
  color: #b7b7b7;
}

.assessment-wrapper :-moz-placeholder {
  /* Firefox 18- */
  color: #b7b7b7;
}

@media (max-width: 1366px) {
  .assessment-wrapper .right-area {
    padding: 0px 2em;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .assessment-wrapper .scroller-group>img {
    right: 0.8em;
  }

  .assessment-wrapper .assessment-status .row .col-sm-3 {
    border-right: 1px solid transparent;
    border-bottom: 1px solid #e4e4e4;
  }

  .assessment-wrapper .assessment-status .row .col-sm-3:last-child {
    border-bottom: 1px solid transparent;
  }
}

.assessment-wrapper select {
  font-size: 1em !important;
  border-radius: 0.25em !important;
  border: solid hsl(0, 0%, 80%) 1px !important;
  height: 39px !important;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  display: block;
  width: 100%;
}

.assessment-wrapper .header-class {
  margin-left: -10px;
  margin-right: -11px;
  margin-top: -14px;
  margin-bottom: 12px;
  padding-left: 14px;
}

.assessment-card {
  -webkit-border-bottom-right-radius: 15px !important;
  -webkit-border-bottom-left-radius: 15px !important;
  -moz-border-radius-bottomright: 15px !important;
  -moz-border-radius-bottomleft: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.assessment-details-view ul {
  display: flex;
}

.assessment-details-view ul li {
  color: #9da0aa;
  padding: 0em 1em;
  position: relative;
  display: grid;
  line-height: 1.5;
}

.assessment-details-view ul li label {
  margin-bottom: 0;
  color: #9e9999de;
  font-weight: 400;
}

.assessment-details-view ul li::after {
  content: "";
  width: 1px;
  background: #e2e2e2;
  height: 100%;
  position: absolute;
  top: 0px;
  right: -2px;
}

.assessment-details-view ul li:last-child::after {
  width: 0;
  background: transparent;
}

.assessment-details-view ul li p {
  color: #818181;
  padding-right: 0em;
  font-weight: bold;
  font-size: 1em !important;
}

.assessment-details-view ul li>.commentBox {
  height: 4em !important;
  width: 100% !important;
}

.assessment-wrapper .right-area .btn:focus {
  color: #ffffff;
}

.assessment-child-textbox {
  width: 98% !important;
}

.assessment-textarea {
  height: 4em !important;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.w-55 {
  width: 55%;
}

.assementAnswer .image-container {
  margin-right: 2em;
  margin-bottom: 0.5em;
}

.survey-view-image {
  max-height: 20vh;
  max-width: auto;
}

.label-disable-color {
  color: #b5b5b5;
}

.disable-img {
  color: #e9ecef !important;
}

.chose-scanner-type label {
  position: relative;
  top: -6px;
  left: 3px;
}

.drop-down {
  width: 98%;
  padding: 18px 5px;
  border-radius: 1px;
  font-size: 17px !important;
}

.survey-questions input[type="text"],
input[type="textarea"],
input[type="number"] {
  width: 98% !important;
}

.survey-questions input[type="text"],
.survey-questions input[type="textarea"] {
  font-size: 17px !important;
}

.survey-questions input[type="text"] {
  padding: 3px 0px;
}

.assessment-textarea {
  height: 4em !important;
  word-wrap: break-word;
  /* Handles long words */
  width: 100%;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.confidential-notice-message-loginpage {
  background-color: #172eb5 !important;
  margin-top: 30px;
  border-radius: 15px !important;
  padding: 15px;
}

.confidential-notice-heading {
  color: white;
  font-family: "Ebrima";
  font-size: 13px;
  margin-bottom: 3px;
  font-weight: bold;
}

.confidential-notice-message {
  color: white;
  font-family: "Ebrima";
  font-size: 12px;
  font-weight: 400;
}

.leagueASC {
  border-style: solid;
  border-color: red;
}

.leagueDESC {
  border-style: solid;
  border-color: green;
}

.leaguePL {
  padding-left: 15px;
}

.leaguePR {
  padding-right: 15px;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(37, 46, 105, 0.2) !important;
}

.info-bar-header {
  margin: 3px 15px -5px 0px;
}

.master-Dash-Card-1 {
  min-height: 600px;
  max-height: 600px;
}

.master-Dash-table-1 {
  height: 400px;
}

.PAD-Grid {
  height: 500px !important;
  overflow-y: scroll !important;
}

.grid-bg-red {
  background-color: #E74231 !important;
}

.grid-bg-yellow {
  background-color: rgba(255, 206, 86, 0.7) !important;
}

.grid-bg-blue {
  background-color: #172EB5 !important;
}

.grid-bg-green {
  background-color: #57C980 !important;
}

.grid-bg-orange {
  background-color: #FF8A00 !important;
}

.vertical-align-content {
  display: flex;
  align-items: center;
}

.planogram-container {
  height: 100%;
  width: 100%;
  overflow: auto;
  border: 4px solid white;
  margin-right: 2px;
  border-radius: 5px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.planogram-outlet-container {
  width: 100%;
  overflow: auto;
  border: 4px solid white;
  margin-right: 2px;
  border-radius: 5px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.no-planogram-message {
  text-align: center;
}

#red-chip.MuiChip-root {
  background-color: #E74231 !important;
  color: white !important;
  font-weight: bold !important;
}

#yellow-chip.MuiChip-root {
  background-color: rgba(255, 206, 86) !important;
  color: white !important;
  font-weight: bold !important;
}

.data-grid-font-12 {
  font-size: 12px !important;
}

.data-grid-header {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.d-flex {
  display: flex;
}

.d-flex-space-between {
  justify-content: space-between;
}

.d-flex-justify-center {
  justify-content: center;
}

.d-flex-end {
  justify-content: flex-end;
}

.d-flex-align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

/* add to homescreen button */
.athContainer {
  background-color: #eeeeee;
  color: #000000;
  display: none;
  position: fixed;
  width: 400px;
  height: 180px;
  bottom: 0;
  right: 0;
  z-index: 1000;
  padding: 2%;

  @media (max-width: 500px) {
    width: 100%;
  }
}

/* survey answer  --end--*/

/* Fixes pagination utils unorganized ui --start--*/
.data-grid-base .MuiTablePagination-toolbar p {
  margin-bottom: 0px;
}

.grid-height {
  height: 500px;
  max-height: 500px;
}

/* Fixes pagination utils unorganized ui --end--*/

legend {
  width: auto !important;
}

.delimiter {
  height: 1px;
  width: 100%;
}

.add-border-no-radius {
  border: 1px solid #000 !important;
  border-radius: 0px !important;
  margin-left: 5px !important;
}

/* swal styling*/
.col-count-link {
  width: 100%;
  height: 100%;
  border: none !important;
  cursor: pointer;
  background: transparent;
}

.col-count-link span {
  color: #5383ff !important;
  text-decoration: underline;
  display: flex;
  justify-content: end;
}

.user-details-alerts .swal-title {
  position: relative;
  top: -75px;
  margin: 0px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
  padding-bottom: 15px;
}

.user-details-alerts .swal-icon--custom {
  position: relative;
  top: 45px;
}

.user-details-alerts .swal-icon--custom img {
  max-height: 48px;
}

.swal-modal.swal-login {
  @media (max-width: 500px) {
    width: calc(100% - 80px);
  }
}

.alert-modal-content {
  background-color: blue !important;
  width: 550px;
  max-height: 100%;
  margin: 300px auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .alert-modal-content {
    background-color: blue !important;
    height: 22%;
    width: 55%;
    margin: 300px auto;
  }
}

@media screen and (max-width: 480px) {
  .alert-modal-content {
    background-color: blue !important;
    height: 30%;
    width: 90%;
    margin: 300px auto;
  }
}

.alert-popup-cross-icon-div {
  display: flex;
  justify-content: flex-end;
}

.alert-popup-icon {
  height: 40px;
  width: 40px;
  margin: 10px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .alert-popup-icon {
    height: 37px;
    width: 40px;
    margin: 10px;
    color: white;
  }
}

@media screen and (max-width: 480px) {
  .alert-popup-icon {
    height: 30px;
    width: 40px;
    margin: 10px;
    color: white;
    font-weight: bold;
  }
}

.alert-popup-icon-image-div {
  margin: 2px 230px;
}

@media screen and (max-width: 1024px) {
  .alert-popup-icon-image-div {
    margin: 2px 235px;
  }
}

@media screen and (max-width: 480px) {
  .alert-popup-icon-image-div {
    margin: 20px 155px;
  }
}


.alert-popup-text-div {
  text-align: center;
  font-size: 22px;
  margin: 20px;
  color: white;
}

@media screen and (max-width: 1024px) {
  .alert-popup-text-div {
    text-align: center;
    font-size: 22px;
    margin: 20px;
    color: white;
  }
}

@media screen and (max-width: 480px) {
  .alert-popup-text-div {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 20px;
    color: white;
  }
}

.user-details-alerts .swal-icon {
  position: relative;
  top: 45px;
}

.user-details-alerts .swal-button--confirm {
  background: #3d4977;
}

.user-details-alerts .swal-footer {
  text-align: center;
}

/* swal Danger mode*/

.user-details-alerts-error .swal-title {
  position: relative;
  top: -75px;
  margin: 0px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
  padding-bottom: 15px;
}

.user-details-alerts-error .swal-icon--custom {
  position: relative;
  top: 45px;
}

.user-details-alerts-error .swal-icon--custom img {
  max-height: 48px;
}

.user-details-alerts-error .swal-icon {
  position: relative;
  top: 45px;
}

.user-details-alerts-error .swal-button--confirm {
  background: #d32f2f;
}

.user-details-alerts-error .swal-footer {
  text-align: center;
}

.true-command-header {
  background-color: #e0e0e0;
}

.app-theme-bg {
  background-color: #3d4977 !important;
  color: white !important;
}

.transfer-list-header {
  text-align: center;
  padding: 24px !important;
  height: 4px;
}

.swal-footer {
  text-align: center;
}

.swal-button-container button:focus {
  box-shadow: none;
}

.grid-components-container {
  justify-content: space-between;
}

.gid-component-wrapper {
  display: flex;
  justify-content: center;
}

.create-new-group-btn {
  width: 12rem !important;
  color: #1976d2 !important;
  border: 1px solid rgba(25, 118, 210, 0.5) !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.add-scroll {
  display: flex;
  align-items: flex-end;
  overflow-y: scroll;
}

.add-scroll::-webkit-scrollbar {
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.tooltip-container {
  position: absolute;
  top: 0;
  right: 7px;
  cursor: pointer;
}

.tooltip-help {
  transition: box-shadow 0.3s ease-in-out;
  background-color: transparent;
  border: none;
  border-radius: 50%;
}

.tooltip-help:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.MuiTooltip-tooltip {
  background-color: rgb(17, 17, 17) !important;
  color: #ffffff;
}


.overlay-span-no-location {
  padding: 1rem;
  background-color: #cccc00;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  position: fixed;
  top: -13rem;
  left: -14rem;
}

.overlay-image-container {
  background-color: #cccc00;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}

.overlay-span-advice {
  padding: 1rem;
  background-color: #ff8a8a;
  color: white;
  position: fixed;
  top: -13rem;
  left: -14rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.overlay-span-no-asset {
  padding: 1rem;
  background-color: #ff8a8a;
  color: white;
  position: fixed;
  top: -13rem;
  left: -8rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.relative-position {
  position: relative;
  background-color: red !important;
}

.outlet-map-info-container {
  position: absolute;
  z-index: 10;
  top: 2rem;
  width: 100%;
}

.outlet-map-info {
  width: max-content;
  padding: 1rem;
  background-color: #cccc00;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.overlay-image-counter-container {
  background-color: #ff8a8a;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
}

.overlay-counter-container {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.overlay-span-right-location {
  padding: 1rem;
  background-color: #32cd32;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  position: fixed;
  top: -13rem;
  left: -10rem;
}

.overlay-container-right-location {
  background-color: #32cd32;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
}

.overlay-counter-right-location {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.survey-create .name-desc-note-card .name {
  margin-top: 3px;
}

.survey-create .name-desc-note-card .note {
  padding-top: 12px;
  margin-bottom: 10px;
  margin-top: 6px !important;
}

.survey-create .cover-head .add-bg-dark-grey {
  background-color: "#E0E0E0";
}

.survey-create .cover-card {
  border-radius: 12px;
  min-height: 200px;
}

.survey-create .cover-head {
  height: 48px;
  background-color: #3d4977;
  color: white;
}

.survey-create .cover-head .add-padding {
  padding: 12px;
}

.fs-18 {
  font-size: 18px;
}

.create-survey-fields-heading {
  display: flex;
  align-items: center;
}

.label-color {
  font-size: 13px !important;
  font-weight: 500;
  font-style: normal;
}

.installation-page {
  display: flex;
  justify-content: end;

}

/*questionnaire related styles*/
.survey-preview-rendered {
  display: none;
  margin: 1rem auto;
}

.hide-question {
  display: none !important;
}

.show-question {
  display: block !important;
}


.survey-questions input[type=text],
input[type=textarea],
input[type=number] {
  width: 98% !important;
}

.survey-questions input[type=text],
.survey-questions input[type=number],
.survey-questions input[type=textarea] {
  font-size: 17px !important;
}

.survey-questions input[type=text] {
  padding: 3px 0px;
}

.survey-box {
  // width:45rem;
  margin: auto;
}

.survey-box .drop-down {
  width: 100%;
  padding: 18px 5px;
  border-radius: 1px;
  font-size: 17px !important;
}

.survey-box .label-disable-color {
  color: #b5b5b5;
}

.survey-box .image-container {
  height: 10vh;
  width: 30%;
  object-fit: cover;
  display: inline-block;
  position: relative;
  margin-top: 1em;
  margin-right: 1em;
}

.survey-box .image-container img {
  width: 76px;
  max-height: 80px
}

.survey-box .survey-view-image {
  max-height: 10vh;
  max-width: auto;
}

.survey-box .imageCountWraper {
  min-width: 45%;
  max-width: 45%;
}

.survey-box .input--file.image-count label {
  left: 0.4rem;
}

.survey-box .input--file {
  position: relative;
  top: 3px;
}

.survey-box .input--file label {
  position: relative;
  top: 3px;
  left: 3px;
}

.survey-box .input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.survey-box input[type=text] {
  padding: 3px 0px 3px 3px;
  border: 1px solid #000;
  border-radius: 3px;
}

.survey-box input[type=radio],
.survey-box input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 4px;
  position: relative;
}

.survey-box input[type=radio] {
  top: 3px;
  height: 16px;
}

.survey-box input[type=checkbox] {
  top: 2px;
  height: 15px;
}

.survey-box .seperator {
  border-bottom: 0.1rem solid #cecbcb;
  margin-bottom: 0.75em;
  padding-bottom: 0.75em;
}

/*-end- questionnaire related styles*/

.recurrence-grid-container {
  height: 400;
  width: 100%;
}

.recurrence-table-container {
  height: 100%;
  border-radius: 0px;
  border: 1px solid grey;
}

.recurrence-category-btn {
  border-radius: 16px !important;
  justify-content: end;
  font-size: 12px !important;
  font-weight: 400;
}

.recurrence-bold-title {
  color: #000;
  font-weight: bold;
}

.recurrence-table-header {
  padding: 15px 5px 15px 5px;
}

.recurrence-grid-container [data-field="Title"] {
  font-weight: 500;
}

.recurrence-grid-container [role="rowgroup"] {
  background-color: #3D4A77;
  color: #fff;
  font-weight: 400;
}

.recurrent-progress-highlight {
  background-color: #41A4F7;
  font-weight: 500;
}

.recurrent-incomplete-highlight {
  background-color: #FFD741;
  font-weight: 500;
}

.recurrent-complete-highlight {
  background-color: #4CB050;
  font-weight: 500;
}

.recurrence-title {
  font-size: 23px !important;
  color: #0062D3;
}

.recurrence-activity {
  font-size: 12px !important;
  color: #ABA39B;
  margin-top: 7px !important;
}

.recurrence-table-title {
  font-size: 22px !important;
  font-weight: 600;
}

.recurrence-table-description {
  font-size: 11px !important;
  font-weight: 200;
}

.recurrence-link-text {
  color: #0062D3 !important;
}

.recurrence-header-separator {
  border-right: 1px solid #000;
}

.custom-recurrence-radio span {
  padding: 0px 0px 0px 0px !important;
  font-size: 12px !important;
}

.recurrence-header-input {
  width: 150px !important;
  padding: 0px 2px 0px 2px !important;
}

.align-recurrence-input {
  margin-top: 10px !important;
  margin-right: 20px;
}

.breadcrumbs-text-title {
  font-size: 25.2px !important;
  text-align: left;
  font-weight: 500 !important;
}

.mui-grid-linkColumn {
  color: #182eb5 !important;
}

.mui-grid-linkColumn:hover {
  cursor: pointer;
}

.dnd-main-container {
  background-color: #fff !important;
}

.draggableHeader {
  width: 100%;
  background-color: #1865C0;
  color: #fff;
  font-weight: 400;
  justify-content: center;
  text-align: center;
  height: 35px;
  border-radius: 5px;
  padding-top: 8px;
}

.draggable-container {
  justify-content: center;
  height: 65%;
}

.dnd-container {
  margin: 0px 20px 20px 20px;
  padding-top: 10px;
  height: 100%;
  width: 100% !important;
  border-top: 1px solid #E0E0E0;
}

.draggable-item {
  color: #3A393A;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 10px;
}

.highlighted {
  background-color: aliceblue;
}

.droppable-area {
  padding: 5px 20px 20px 20px;
  min-height: 100vh;
  border: 1px solid #E0E0E0;
  margin-left: 20px;
  justify-content: center;
  width: 94%;
}

.draggable-item .question-type {
  margin: 0px !important;
  font-weight: 500;
  font-size: medium;
}

.draggable-item .question-subtype {
  margin: 3px 0px 0px 0px !important;
  font-size: smaller;
}

.question-container {
  width: 96%;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  padding-bottom: 30px;
  border: 1px solid #3A393A !important;
}

.question-number {
  font-weight: 500;
}

.question-text {
  margin-bottom: 15px;
}

.float-right {
  float: right;
}

.question-extra {
  display: 'flex';
  justify-content: 'center';
  margin-top: 10px;
}

.question-extra span {
  margin: 15px;
}

.question-extra svg {
  margin: 0px;
}

.question-extra-right {
  float: right;
  /* align-items: right; */
  /* margin-bottom: 30px; */
}

.settings {
  margin-bottom: 20px;
  color: #3A393A !important;
}

.question-header-container {
  width: 96%;
  padding: 0px;
  margin: auto;
}

.delete-option-icon {
  cursor: pointer;
  // margin-right: 13px;
  margin-left: 10px;
  // padding-top: 3px;
}

.status-margin {
  margin-top: -8px !important;
}

.edit-question-settings {
  margin: 40px 10px 10px 10px;
  padding: 10px;
  // width: 90% !important;
}

.hide-view {
  display: none !important;
}

.no-margin {
  margin: 0px !important;
}

.planogram-card {
  max-height: 89vh;
  width: 100%;
}

.stitched-image-card {
  max-height: 100%;
  height: 100%;
}

.asset-report-image {
  width: 100% !important;
}

.clippedText {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  WebkitLineClamp: 2;
  WebkitBoxOrient: 'vertical';
  textOverflow: 'ellipsis';
  cursor: pointer;
  max-height: 3em;
}

.clippedText:after {
  content: ' ... more';
  position: absolute;
  bottom: 0;
  right: 0;
}

.fullText {
  display: block;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.wrapped-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
}

.wrapped-text-outlet-box {
  max-width: 1200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
}

.outlet-Screen-tab-container {
  margin-right: -1% !important;
}

.outlet-left-container-image {
  margin-left: -15px !important;
  margin-right: 15px !important;
}

.outlet-left-container-image {
  margin-left: -15px !important;
  margin-right: 15px !important;
}

#mdidle-container-outlet-data {
  border: 1px solid black !important;
  border-radius: 5px !important;
  margin-top: 0px !important;
  margin-right: 10px !important;
  margin-left: -10px !important;
}

.expand-more-less-icon {
  display: none !important;
}

.expand-more-less-icon-asset {
  display: none !important;
}

@media (max-width: 768px) {
  #mdidle-container-outlet-data {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  #mdidle-container-outlet-data {
    margin-top: 15px !important;
  }

  .order-product-container-box-outlet {
    margin-top: 15px !important;
  }

  .ordering-assest-tab-scroll-container {
    width: 70%;
  }

  .expand-more-less-icon-asset {
    display: block !important;
    position: relative !important;
    margin-left: 15% !important;
  }

  .expand-more-less-icon {
    display: block !important;
  }
}

@media (max-width: 500px) {
  .left-container-outlet-data {
    margin-left: 25px;
  }
}

.order-product-container-box-outlet {
  border: 1px solid black;
  border-radius: 5px;
  margin-top: 0px !important;
}

@media (max-width: 768px) {
  .order-product-container-box-outlet {
    margin-top: 15px !important;
  }
}

.wrapped-text-outlet-box {
  max-width: 1200px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.missed-opportunity-client-select div div {
  width: -webkit-fill-available; /* For WebKit browsers */
  width: -moz-available;        /* For Firefox */
  width: fill-available;        /* Standard (where supported) */
  width: 100%;                  /* Fallback for older browsers */
}

.title-max-width {
  max-width: 1540px;
}